import { httpOrigin } from '~configs/apiOrigin';
import { apiVersion } from './platform';

export const headers = (params?: { [key: string]: string }) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  Authorization: `${sessionStorage.getItem('token')}`,
  ...(params || {}),
});

export const userSettingsUrl = (domain?: string, key?: string) => {
  const domainStr = domain ? `domain=${domain}` : '';
  const keyStr = key ? `key=${key}` : '';
  let qStr = domainStr && keyStr ? `?${domainStr}&${keyStr}` : '';
  qStr = domainStr && !keyStr ? `?${domainStr}` : qStr;
  qStr = !domainStr && keyStr ? `?${keyStr}` : qStr;

  return httpOrigin(
    `/trader-rest-service/${apiVersion()}/user-settings${qStr}`
  );
};
