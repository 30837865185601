import { utcToZonedTime, format } from 'date-fns-tz';

import {
  DISABLE_UI,
  USER_PERMISSIONS,
  SYSTEM_STATUS,
  LATENCY,
  SET_STATS,
  SET_SETTINGS,
  SEND_SETTINGS,
  REMOVE_SETTINGS,
  PAUSE_TRADING,
  RESUME_TRADING,
  MATCHER_STATUS,
  CANCEL_ALL_ORDERS,
  LOAD_INSTRUMENT_PRICING_READY,
  SESSION_STATUS,
  SESSION_SERVICE_STARTUP,
  SET_CURRENT_DATE,
  METALS_FIXING_MARKET_STATUS_UPDATE,
} from './action_types';

export const reduxSendSettings = (settingsObj, domain) => (dispatch) =>
  dispatch({
    type: SEND_SETTINGS,
    payload: { settingsObj, domain },
  });

export const reduxRemoveSettings = (identifier, domain) => ({
  type: REMOVE_SETTINGS,
  payload: { identifier, domain },
});

export const reduxSetUserSettings = (settingsObj, domain) => (dispatch) =>
  dispatch({
    type: SET_SETTINGS,
    payload: { settingsObj, domain },
  });

export const pauseTrading = (msecs) => (dispatch) => {
  dispatch({
    type: PAUSE_TRADING,
  });
  setTimeout(() => {
    dispatch({
      type: RESUME_TRADING,
    });
  }, msecs);
};

export const resumeTrading = () => (dispatch) =>
  dispatch({
    type: RESUME_TRADING,
  });

export const reduxCancelAllOrders = () => (dispatch) =>
  dispatch({
    type: CANCEL_ALL_ORDERS,
  });

export const reduxDisableUI = (payload) => (dispatch) =>
  dispatch({
    type: DISABLE_UI,
    payload: { disable_ui: payload },
  });

export const userPermissions = (payload) => (dispatch) =>
  dispatch({
    type: USER_PERMISSIONS,
    payload: { permissions: payload },
  });

export const reduxSetSystemStatus = (payload) => (dispatch) =>
  dispatch({
    type: SYSTEM_STATUS,
    payload,
  });

export const reduxSetMatcherSystemStatus = (payload) => (dispatch) =>
  dispatch({
    type: MATCHER_STATUS,
    payload,
  });

export const reduxSetLoadInstrumentPricingReady = (payload) => (dispatch) =>
  dispatch({
    type: LOAD_INSTRUMENT_PRICING_READY,
    payload,
  });

export const reduxSetSessionStatus = (payload) => (dispatch) =>
  dispatch({
    type: SESSION_STATUS,
    payload,
  });

export const reduxSetSessionServiceStartup = () => (dispatch) =>
  dispatch({
    type: SESSION_SERVICE_STARTUP,
  });

export const reduxWSLatency = (payload) => (dispatch) =>
  dispatch({
    type: LATENCY,
    payload,
  });

export const reduxSetStats = (payload) => (dispatch) =>
  dispatch({
    type: SET_STATS,
    payload,
  });

export const reduxSetCurrentDate = () => (dispatch) =>
  dispatch({
    type: SET_CURRENT_DATE,
    payload: format(
      utcToZonedTime(new Date(), 'America/New_York'),
      'yyyy-MM-dd'
    ),
  });

export const reduxMetalsFixingMarketStatusUpdate = (payload) => (dispatch) =>
  dispatch({
    type: METALS_FIXING_MARKET_STATUS_UPDATE,
    payload,
  });
