import logger from '~utils/logger';
import { headers, userSettingsUrl } from '~utils/apiCalls';
import { SettingsType } from '~types/settings';

const getSettingByDomainAndKey = async (
  domain: string,
  key: string
): Promise<SettingsType | null> => {
  const origin = userSettingsUrl(domain, key);

  try {
    const response = await fetch(origin, {
      method: 'GET',
      headers: headers(),
    });

    const data = (await response.json()) as SettingsType;

    if (!response.ok && response.status !== 200) {
      throw new Error(
        `user settings pull failed for ${domain}${key ? `, ${key}` : ''}`
      );
    }

    return data;
  } catch (err) {
    logger.logCriticalError(err);

    return null;
  }
};

export default getSettingByDomainAndKey;
