import logger from './logger';

const dateFromServer = (domain: string): Date | string => {
  let date;
  try {
    const xmlHTTP = new XMLHttpRequest();
    xmlHTTP.open('HEAD', `${domain}?_=${new Date().getTime()}`, false);
    xmlHTTP.setRequestHeader('Cache-Control', 'no-cache');
    xmlHTTP.send();
    date = xmlHTTP.getResponseHeader('Date');
  } catch (e) {
    if (sessionStorage) logger.logWarning(e.message);
    date = new Date();
  }
  return date;
};

export default dateFromServer;
